import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-greensboro-north-carolina.png'
import image0 from "../../images/cities/scale-model-of-the-beatles-pilgrimage-tour-in-greensboro-north-carolina.png"
import image1 from "../../images/cities/scale-model-of-greensboro-children's-museum-in-greensboro-north-carolina.png"
import image2 from "../../images/cities/scale-model-of-greensboro-history-museum-in-greensboro-north-carolina.png"
import image3 from "../../images/cities/scale-model-of-greensboro-science-center-in-greensboro-north-carolina.png"
import image4 from "../../images/cities/scale-model-of-blandwood-mansion-in-greensboro-north-carolina.png"
import image5 from "../../images/cities/scale-model-of-international-civil-rights-center-&-museum-in-greensboro-north-carolina.png"
import image6 from "../../images/cities/scale-model-of-wet'n-wild-emerald-pointe-water-park-in-greensboro-north-carolina.png"
import image7 from "../../images/cities/scale-model-of-greensboro-area-convention-&-visitors-bureau-in-greensboro-north-carolina.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Greensboro'
            state='North Carolina'
            image={image}
            lat='36.0726354'
            lon='-79.79197540000001'
            attractions={ [{"name": "The Beatles Pilgrimage Tour", "vicinity": "3912 Battleground Ave #112, Greensboro", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 36.143333, "lng": -79.86630430000002}, {"name": "Greensboro Children's Museum", "vicinity": "220 N Church St, Greensboro", "types": ["museum", "park", "point_of_interest", "establishment"], "lat": 36.075226, "lng": -79.78607399999999}, {"name": "Greensboro History Museum", "vicinity": "130 Summit Ave, Greensboro", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.075657, "lng": -79.788027}, {"name": "Greensboro Science Center", "vicinity": "4301 Lawndale Dr, Greensboro", "types": ["aquarium", "zoo", "museum", "point_of_interest", "establishment"], "lat": 36.129929, "lng": -79.83411699999999}, {"name": "Blandwood Mansion", "vicinity": "447 W Washington St, Greensboro", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.0697988, "lng": -79.79556009999999}, {"name": "International Civil Rights Center & Museum", "vicinity": "134 S Elm St, Greensboro", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.0717161, "lng": -79.79068749999999}, {"name": "Wet'n Wild Emerald Pointe Water Park", "vicinity": "3910 S Holden Rd, Greensboro", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 36.0064093, "lng": -79.83868860000001}, {"name": "Greensboro Area Convention & Visitors Bureau", "vicinity": "2411 W Gate City Blvd, Greensboro", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 36.055978, "lng": -79.83010339999998}] }
            attractionImages={ {"The Beatles Pilgrimage Tour":image0,"Greensboro Children's Museum":image1,"Greensboro History Museum":image2,"Greensboro Science Center":image3,"Blandwood Mansion":image4,"International Civil Rights Center & Museum":image5,"Wet'n Wild Emerald Pointe Water Park":image6,"Greensboro Area Convention & Visitors Bureau":image7,} }
       />);
  }
}